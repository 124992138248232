import { Link } from "gatsby";
import React, { useState } from "react";

//Style Sheets
import "../styles/nav.scss";
import "../styles/index.scss";

//Media
import logo from "../images/strategix-logo.svg";
import hamburger from "../images/hamburger.png";
// import { img } from 'gatsby-plugin-image';

export default function Navbar2() {
  const [showServices, setShowServices] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const [mobileMenu, setMobileMenu] = useState(false);

  const props = {
    menuClass: !mobileMenu ? "mobile-menu" : "mobile-menu open",
  };

  return (
    <>
      <nav>
        <div className="nav-logo">
          <img
            src={logo}
            width={200}
            height={100}
            alt="strategix technology solutions logo"
            className="resp-image"
          />
        </div>
        <div className="nav-menu">
          <div className="nav-menu-inner">
            <div
              className="desktop-menu"
              style={{
                zIndex: "9999",
                justifyContent: "flex-end",
                paddingRight: "20px",
              }}
            >
              <button
                className="flex ac je shaker"
                style={{ marginTop: "-5px" }}
              >
                Book free consultation
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
