import * as React from "react";
import Layout from "../components/Layout";
import PartnerCarousel from "../components/carousels/PartnerCarousel";
// import { motion, useAnimation } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import ScrollAnimation from "react-animate-on-scroll";

//Images
import rsaFlag from "../images/rsa-flag.svg";
import bbbee from "../images/bbbee.svg";
import blueprint from "../images/Blueprint.png";
import mobileblueprint1 from "../images/mobile-bp-1.png";
import mobileblueprint2 from "../images/mobile-bp-2.png";
import mobileblueprint3 from "../images/mobile-bp-3.png";
import willie from "../images/willie.png";

import rubStsAdp from "../markdown-pages/strategix-technology-solutions-adp-rubrik.png";
import trust2 from "../images/Professional Services/10f3.png";

import dell from "../images/partners/dell.svg";
import vmware from "../images/partners/vmware.svg";
import veeam from "../images/partners/veeam.svg";

import "animate.css/animate.min.css";

import countries from "../images/numbers/countries.svg";
import consultants from "../images/numbers/consultants.svg";
import continents from "../images/numbers/continents.svg";
import years from "../images/numbers/years.svg";

import squareElement2 from "../images/square-element2.svg";
import squareElement3a from "../images/square-element3a.svg";
import squareElement3b from "../images/square-element3b.svg";
import squareElement3c from "../images/square-element3b.svg";
import squareElement3d from "../images/red-square.svg";

import stsLogo from "../images/gatsby-icon.png";
import rdpLogo from "../images/rdpLogo.webp";
import adpLogo from "../images/adpLogo.webp";

import check from "../images/check.svg";

//CSS
import "../styles/index.scss";
import "../styles/landingPage.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import "../styles/animations.scss";
import TestimonialCarousel2 from "../components/carousels/TestimonialCarousel2";
import ContactForm from "../components/ContactForm";
import homeFlipBox from "../components/flipboxes/homeFlipBox";
import bbbeee from "../components/bbbeee";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Footer from "../components/Footer";
// import { img } from 'gatsby-plugin-image';
import CookieConsent from "react-cookie-consent";
import Navbar2 from "../components/Navbar2";
import LandingFlipBox from "../components/flipboxes/landingFlipbox";

import cyberRed from "../lotties/cyber-red.json";
import cyberRed2 from "../lotties/cyber-red2.json";
import LottiePlayerLight from "react-lottie-player/dist/LottiePlayerLight";
import RubrikContactForm from "../components/RubrikContactForm";
import TextCarousel from "../components/carousels/TextCarousel";

// markup
const RubrikDP = () => {
  const [ref, inView] = useInView();

  let interval = 1000;

  const countUp = () => {
    if (typeof window !== "undefined") {
      let valueDisplays = document.querySelectorAll(".num");

      valueDisplays.forEach((valueDisplay) => {
        let startValue: number = 0;
        let endValue: any = valueDisplay.getAttribute("data-val");
        let endValue2 = parseInt(endValue);
        let duration = Math.floor(interval / endValue2);
        let counter = setInterval(function () {
          startValue += 1;
          valueDisplay.textContent = startValue.toString() + "+";
          if (startValue == endValue2) {
            clearInterval(counter);
          }
        }, duration);
      });
    }
  };

  React.useEffect(() => {
    if (inView) {
      countUp();
    }
  }, [inView]);

  const [screenWidth, setScreenWidth] = React.useState(0);

  const updateScreenX = () => {
    if (typeof window != "undefined") {
      setScreenWidth(window.innerWidth);
    }
  };

  React.useEffect(() => {
    updateScreenX();
    if (typeof window != "undefined") {
      window.addEventListener("resize", updateScreenX);
    }
  }, []);

  const props = {
    seoTitle: "Home",
    seoDescription:
      "Strategix Technology Solutions is a leading provider of managed and professional IT services, enabling multicloud operations through leading engineering skills.",
  };

  return (
    <>
      <Navbar2 />

      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* Home Hero */}
        <section>
          <div className="hero" id="landingHero">
            <div className="w-max">
              <div className="grid-column-2">
                <div
                  className="flex-col-c fade-intro landing-text"
                  style={{ minHeight: "88vh" }}
                >
                  <div className="home-text-mobile">
                    <ScrollAnimation
                      animateIn="animate__fadeInUp"
                      duration={0.5}
                    >
                      <h1>Is your business Ransomware-Ready?</h1>
                    </ScrollAnimation>
                    <br />
                    <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
                      <p>
                        Strategix helps executives at South Africa's largest
                        companies protect and manage their business's critical
                        data, systems and infrastructure from ransomware.
                      </p>
                      <div className="flex btn-cont">
                        <button className="jump">
                          <a href="#contact">Book free consultation</a>
                        </button>

                        <button>
                          <a href="#contact">
                            Download Free Ransomware Readiness Checklist
                          </a>
                        </button>
                      </div>{" "}
                    </ScrollAnimation>
                  </div>
                </div>
                <div style={{ paddingTop: "80px" }}>
                  <LottiePlayerLight animationData={cyberRed} loop play />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Clients */}
        <section
          className="half"
          style={{ zIndex: "9", minHeight: "100px", paddingBlock: "50px" }}
        >
          <div className="clients-wrapper flex col ac jc">
            {/* <TestimonialCarousel2 /> */}
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
              <TextCarousel />
            </ScrollAnimation>
          </div>
        </section>{" "}
        <div className="square-element3">
          <img alt="" src={squareElement3b} />
          <img alt="" src={squareElement3a} />
        </div>
        {/* Refuse the ransom */}
        <section className="refuse-ransom-img flex ac jc">
          <div className="wrapper grid col-2 w-max">
            {/* <img alt="" src={squareElement3b} /> */}
            <div>
              <LottiePlayerLight animationData={cyberRed2} loop play />
            </div>
            <div className="refuse-ransom flex col as jc">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
                <h1 style={{ padding: "0" }}>Refuse the Ransom</h1>
                <h3>
                  RECOVER INSTANTLY FROM RANSOMWARE WITH ROBUST DATA
                  PROTECTION-AS-SERVICE.
                </h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__fadeInUp"
                duration={1}
                delay={1}
              >
                <div className="ransom-approach">
                  {" "}
                  <li>
                    Out think the enemy
                    <p>Define a robust ransomware recovery strategy</p>
                  </li>
                  <li>
                    See what they can't
                    <p>
                      Monitor network and systems to quickly weed out suspicious
                      activities before threats become realities.
                    </p>
                  </li>
                  <li>
                    Stop them in their tracks
                    <p>
                      Prevent breaches from occurring with bulletproof security
                      measures, including access control, security assessments
                      and vulnerability checks.
                    </p>
                  </li>
                  <li>
                    Be prepared for the inevitable
                    <p>
                      Recover instantly from ransomware with immutable data
                      backups that cant be deleted or encrypted.
                    </p>
                  </li>
                </div>
              </ScrollAnimation>
              <div className="flex btn-cont">
                <button>
                  <a href="contact">Book free consultation</a>
                </button>

                <button>
                  <a href="#contact">
                    Download Free Ransomware Readiness Checklist
                  </a>
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </section>
        {/* Partnering with ADP */}
        <section className="half" style={{ zIndex: "9" }} id="strategix-group">
          <div className="sect-wrap">
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp">
                <h2
                  className="fade-in-up"
                  style={{
                    zIndex: "9",
                    textAlign: "center",
                    color: "var(--red)",
                  }}
                >
                  JOIN MORE THAN 5000 GLOBAL ORGANISATIONS USING RUBRIK TO
                  SECURE THEIR DATA.
                </h2>
              </ScrollAnimation>{" "}
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <div className="sts-partner-img">
                  <img alt="" src={stsLogo} className="image-fit" />
                  <img alt="" src={rdpLogo} className="image-fit" />
                  <img alt="" src={adpLogo} className="image-fit" />
                </div>{" "}
                <h3 style={{ textAlign: "center", textTransform: "none" }}>
                  Strategix partners with Assured Data Protection, global
                  leaders in the provision of managed data protection and
                  recovery services for Rubrik to bring over 200 years of
                  collective experience, at your service.
                </h3>
              </ScrollAnimation>
            </div>
          </div>
        </section>
        <div
          className="square-element1"
          style={{ transform: "rotate(180deg)" }}
        >
          <img
            alt=""
            src={squareElement3c}
            style={{ transform: "scale(0.5)" }}
          />
        </div>
        <div
          className="square-element1b"
          style={{ transform: "rotate(180deg)" }}
        >
          <img
            alt=""
            src={squareElement3d}
            style={{ transform: "scale(0.5)" }}
          />
        </div>
        <div className="square-element2">
          <img alt="" src={squareElement2} />
        </div>
        {/* Willie */}
        <section className="sect-wrap team-wrap rubrik-team" id="team">
          <div className="w-max">
            <br />
            <br />
            <div className="team">
              <div className="team-member fade-intro">
                <div id="team-image">
                  <img alt="" src={willie} className="image-fit" />
                </div>
                <div id="team-bio">
                  <h2>Willie Jansen van Rensburg</h2>
                  <h3>Cloud Technologies Group Executive</h3>
                  <h6 style={{ fontStyle: "italic", color: "black" }}>
                    "Ransomware groups are more sophisticated than ever, and
                    increasingly targeting major South African organisations;
                    think of recent attacks on Shoprite and Transnet. This is
                    why we have partnered with ADP and Rubrik to deliver
                    internationally leading managed services to protect South
                    African businesses."
                  </h6>
                  <div className="flex btn-cont">
                    <button style={{ paddingLeft: "10px" }}>
                      <a href="#contact">Book free consultation</a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Our Offerings */}
        <section>
          <div className="offerings-wrapper" style={{ paddingBlock: "5rem" }}>
            <div className="w-max">{LandingFlipBox()}</div>
          </div>
        </section>
      </main>

      {/* Contact */}
      <a id="contact"></a>
      <section className="half">
        <RubrikContactForm />
      </section>
      <CookieConsent
        location="bottom"
        buttonText="I UNDERSTAND &#x279C;"
        cookieName="strategix-cookie"
        style={{
          background: "var(--blue)",
          opacity: "0.9",
        }}
        buttonStyle={{
          color: "#fff",
          backgroundColor: "var(--red)",
          fontSize: "16px",
          borderRadius: "10px",
          padding: "10px 20px",
        }}
        expires={150}
      >
        <p
          style={{
            fontSize: "16px",
            paddingInline: "20px",
          }}
        >
          {" "}
          This website uses cookies in order to offer you the most relevant
          information. By clicking "I UNDERSTAND" you agree to us using cookies
          for optimal performance. For full details, please view our{" "}
          <a
            href="/privacy-policy"
            style={{ color: "white", fontStyle: "italic" }}
          >
            Privacy Policy.
          </a>
        </p>
      </CookieConsent>
      <Footer />
    </>
  );
};

export default RubrikDP;
