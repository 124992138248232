import React, { Component } from "react";
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";

import { StaticImage } from "gatsby-plugin-image";

import "../../styles/partnerCarousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class TextCarousel extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 1200,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: true,
      dots: true,
    };
    return (
      <div
        // className="carousel-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="w-max-nf">
          <div>
            <h1>South Africa is the epicenter of cyber crime</h1>
            <Slider {...settings}>
              <div>
                <h2
                  style={{
                    textAlign: "center",
                    color: "var(--red)",
                    zIndex: 999,
                  }}
                >
                  South African Ransomware attacks have increased 227%
                  year-on-year.
                </h2>
              </div>
              <div>
                <h2
                  style={{
                    textAlign: "center",
                    color: "var(--red)",
                    zIndex: 999,
                  }}
                >
                  South African organisations experienced the largest annual
                  increase in ransomware attacks globally.
                </h2>
              </div>
              <div>
                <h2
                  style={{
                    textAlign: "center",
                    color: "var(--red)",
                    zIndex: 999,
                  }}
                >
                  Businesses are targeted every 27 seconds on average.
                </h2>
              </div>
            </Slider>
          </div>

          <br />
          <br />
        </div>
      </div>
    );
  }
}
