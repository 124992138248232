import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";

// import Axios from 'axios';

import "../styles/contactForm.scss";
import "../styles/grids.scss";

export default function RubrikContactForm() {
  const [align, setAlign] = useState(true);
  const [ebook, setEbook] = useState(true);
  const [fullName, setFullName] = useState("");
  const RECAPTCHA_KEY: string = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY!;

  // const url: any = process.env.API_ENDPOINT;

  const [data, setData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    organisation: "",
  });

  function handle(e: any) {
    const newData: any = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  useEffect(() => {
    setEbook(false);
  }, []);

  return (
    <>
      <div className="contact-svg" />
      <div
        className="contact-form rubrik-contact-form-wrapper"
        style={{
          maxWidth: "100vw",
          alignItems: "center",
        }}
      >
        <div className="w-max">
          <div className="grid-column-2">
            <div id="contact-tagline" style={{ gridColumn: "span 1" }}>
              <ScrollAnimation animateIn="animate__fadeIn" duration={1}>
                <h1 style={{ paddingBottom: "2.5rem", textAlign: "left" }}>
                  Don't wait until its too late.
                </h1>
                <p>
                  Fortify your organisation's critical systems, data and
                  applications. Take the first step in your data protection
                  journey and get ransomware-ready today.
                  <br />
                  <br />
                  Strategix and Managed Services partner Assured Data Protection
                  help organisations of all sizes develop, implement and manage
                  bulletproof data protection and recovery.
                  <br />
                  <br />
                  Join over 5000 global organisations using Rubrik to safeguard
                  critical data, systems and applications.{" "}
                </p>
                <br />
                <br />
                <button
                  style={{ maxWidth: "100%" }}
                  onClick={() => setEbook(true)}
                >
                  Free Ebook - Ransomware Readiness Checklist
                  {ebook && (
                    <form
                      name="ransomware-readiness-checklist"
                      method="post"
                      data-netlify="true"
                      className="form column"
                      action="/thank-you-checklist"
                      data-netlify-recaptcha="true"
                      style={{ padding: "0" }}
                    >
                      <br />
                      <input type="hidden" name="bot-field" />
                      <input
                        type="hidden"
                        name="form-name"
                        value="ransomware-readiness-checklist"
                      />
                      <div className="row">
                        <div className="column">
                          <input
                            onChange={(e) => setFullName(e.target.value)}
                            value={fullName}
                            name="fullName"
                            id="fullName"
                            type="text"
                            tabIndex={1}
                            required
                            className="form-input"
                            placeholder="Full Name*"
                          />
                        </div>
                        <div className="column">
                          <input
                            onChange={(e) => handle(e)}
                            value={data.email}
                            name="email"
                            id="email"
                            type="email"
                            tabIndex={5}
                            required
                            className="form-input"
                            placeholder="Email *"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="column">
                          <input
                            onChange={(e) => handle(e)}
                            value={data.organisation}
                            name="organisation"
                            id="organisation"
                            type="text"
                            tabIndex={3}
                            required
                            className="form-input"
                            placeholder="Organisation*"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="column">
                          <label
                            style={{
                              fontSize: "14px",
                              color: "#626366",
                              paddingRight: "20px",
                            }}
                          >
                            <input
                              type="checkbox"
                              id="popi"
                              name="popi"
                              value="popi"
                              required
                              style={{
                                fontSize: "20px",
                                marginTop: "24px",
                                marginLeft: "5px",
                                minHeight: "0",
                              }}
                            />
                            <span
                              style={{
                                fontWeight: "300",
                                textTransform: "none",
                              }}
                            >
                              {" "}
                              &nbsp; I agree and consent to the{" "}
                              <Link
                                to="/privacy-policy"
                                style={{ color: "#005b85" }}
                              >
                                <strong>Privacy Policy</strong>
                              </Link>
                              , specifically consenting to Strategix Technology
                              Solutions processing my data and communicating
                              with me according to that policy.
                            </span>
                          </label>
                        </div>
                      </div>

                      <ReCAPTCHA
                        sitekey={RECAPTCHA_KEY}
                        style={{ marginLeft: "1rem" }}
                      />
                      <br />
                      <div className="row">
                        <div className="column button">
                          <button
                            name="submit"
                            value="Submit"
                            type="submit"
                            id="contact-submit"
                            data-submit="...Sending"
                          >
                            SUBMIT <span>&#x279C;</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </button>{" "}
              </ScrollAnimation>
            </div>
            <div
              className="contact-info"
              style={{
                gridColumn: "span 1",
                background: "var(--grey)",
                paddingRight: "2rem",
              }}
            >
              <ScrollAnimation animateIn="animate__fadeIn" duration={1}>
                <h1
                  style={{
                    paddingBottom: "2.5rem",
                    paddingLeft: "2rem",
                    textAlign: "left",
                  }}
                >
                  Book Free Consultation
                </h1>

                <style dangerouslySetInnerHTML={{ __html: "\n\n" }} />

                <form
                  name="rubrik-contact-form"
                  method="post"
                  data-netlify="true"
                  className="form column"
                  action="/thank-you-consultation"
                  data-netlify-recaptcha="true"
                >
                  <input type="hidden" name="bot-field" />
                  <input
                    type="hidden"
                    name="form-name"
                    value="rubrik-contact-form"
                  />
                  <div className="row">
                    <div className="column">
                      <input
                        onChange={(e) => handle(e)}
                        value={data.firstName}
                        name="name"
                        id="firstName"
                        type="text"
                        tabIndex={1}
                        required
                        className="form-input"
                        placeholder="Name*"
                      />
                    </div>
                    <div className="column">
                      <input
                        onChange={(e) => handle(e)}
                        value={data.lastName}
                        name="surname"
                        id="lastName"
                        type="text"
                        tabIndex={2}
                        className="form-input"
                        placeholder="Surname"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="column">
                      <input
                        onChange={(e) => handle(e)}
                        value={data.organisation}
                        name="organisation"
                        id="organisation"
                        type="text"
                        tabIndex={3}
                        required
                        className="form-input"
                        placeholder="Organisation*"
                      />
                    </div>
                    <div className="column">
                      <input
                        onChange={(e) => handle(e)}
                        value={data.phone}
                        name="phone"
                        id="phone"
                        type="tel"
                        tabIndex={4}
                        className="form-input"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="column">
                      <input
                        onChange={(e) => handle(e)}
                        value={data.email}
                        name="email"
                        id="email"
                        type="email"
                        tabIndex={5}
                        required
                        className="form-input"
                        placeholder="Email *"
                      />
                    </div>
                    <div className="column">
                      <select
                        name="enquiry"
                        id="enquiry"
                        tabIndex={6}
                        required
                        placeholder="Preferred Contact Method *"
                        className={align ? "select-align" : "select-left"}
                        defaultValue="type"
                      >
                        <option value="" hidden>
                          Preferred Contact Method * &#9660;
                        </option>
                        <option value="phone" onClick={() => setAlign(false)}>
                          Phone
                        </option>
                        <option value="email" onClick={() => setAlign(false)}>
                          Email
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="column">
                      <label
                        style={{
                          fontSize: "14px",
                          color: "#626366",
                          paddingRight: "20px",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="popi"
                          name="popi"
                          value="popi"
                          required
                          style={{
                            fontSize: "20px",
                            marginTop: "24px",
                            marginLeft: "5px",
                            minHeight: "0",
                          }}
                        />
                        <span style={{ fontWeight: "300" }}>
                          {" "}
                          &nbsp; I agree and consent to the{" "}
                          <Link
                            to="/privacy-policy"
                            style={{ color: "#005b85" }}
                          >
                            <strong>Privacy Policy</strong>
                          </Link>
                          , specifically consenting to Strategix Technology
                          Solutions processing my data and communicating with me
                          according to that policy.
                        </span>
                      </label>
                    </div>
                  </div>

                  <ReCAPTCHA
                    sitekey={RECAPTCHA_KEY}
                    style={{ marginLeft: "1rem" }}
                  />
                  <br />
                  <div className="row">
                    <div className="column button">
                      <button
                        name="submit"
                        value="Submit"
                        type="submit"
                        id="contact-submit"
                        data-submit="...Sending"
                        style={{ maxWidth: "100%" }}
                      >
                        SUBMIT AND REQUEST CONSULTATION <span>&#x279C;</span>
                      </button>
                    </div>
                  </div>
                  {/* </GoogleReCaptchaProvider> */}
                </form>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
