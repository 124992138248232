// import { img } from 'gatsby-plugin-image';
import React from "react";

import linkedin from "../images/linkedin.png";
import youtube from "../images/youtube.png";

export default function Footer() {
  return (
    <div className="footer">
      <div className="w-max-nf">
        <div>
          <a
            href="https://www.linkedin.com/company/strategix-technology-solutions-pty-ltd/"
            target="_blank"
          >
            <img
              alt=""
              src={linkedin}
              className="image-fit"
              style={{ width: "25px", height: "25px", paddingRight: "25px" }}
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCjY3SfY2U53NiO-TNRwyMpg"
            target="_blank"
          >
            <img
              alt=""
              src={youtube}
              className="image-fit"
              style={{ width: "25px", height: "25px" }}
            />
          </a>
        </div>
        <div>
          Copyright © 2022 Strategix Group | Designed and Developed by{" "}
          <a href="https://auxo.digital/" style={{ color: "#ffffff" }}>
            Auxo Digital
          </a>
        </div>
      </div>
    </div>
  );
}
