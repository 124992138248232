import React from "react";

import "../../styles/card.scss";
//Icons
import ScrollAnimation from "react-animate-on-scroll";

import rdpi1 from "../../images/rdpi1.svg";
import rdpi2 from "../../images/rdpi2.svg";
import rdpi3 from "../../images/rdpi3.svg";
import rdpi4 from "../../images/rdpi4.svg";
import rdpi5 from "../../images/rdpi5.svg";
import rdpi6 from "../../images/rdpi6.svg";

import Card from "../Card";

export default function LandingFlipBox() {
  return (
    <div className="outer-container" id="landing-card">
      <div className="row">
        {/******** Desktop Cards *******/}

        <div className="desktop-card2">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1} delay={0}>
            <div className="inner-container-3">
              <Card
                icon={rdpi6}
                heading="Pay only for what you need"
                visibility="true"
                info="Our managed services are provided in a flexible, pay as you use model - allowing you to only play for what you use, and no more."
              />
            </div>{" "}
          </ScrollAnimation>
        </div>

        <div className="desktop-card2">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={100}
          >
            <div className="inner-container-3">
              <Card
                icon={rdpi2}
                heading="Access The Best Technology"
                automation="true"
                info="Access market leading hardware and technology - with 0 capital investment."
              />
            </div>
          </ScrollAnimation>
        </div>

        <div className="desktop-card2">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={200}
          >
            <div className="inner-container-3">
              <Card
                icon={rdpi5}
                heading="Expert team, without the overhead"
                application="true"
                info="Access the industry's best data protection experts as a part of your team, at a fraction of the cost of hiring full time staff."
              />
            </div>{" "}
          </ScrollAnimation>
        </div>

        {/* Mobile Here */}
      </div>
    </div>
  );
}
